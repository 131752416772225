<template>
  <div class="log">
    <app-header title="查看日志" :isShowBack="true"></app-header>
    <div class="log_content">
      本次已导入<span>{{ leaseCount }}</span
      >条排房租约信息，成功导入：<span style="color: #30bac1">{{
        planSuccessCount
      }}</span
      >人，失败：<span style="color: red">{{ planFailCount }}</span>
      人。请在租约列表中查看导入的租约排房信息。<el-button
        type="primary"
        plain
        size="small"
        @click="exportErrorLog"
        >导出失败日志</el-button
      >
    </div>
    <div class="log_list" v-show="showErrorList">
      <div class="log_list_tip">
        {{
          createdDate
        }}
        批量导入排房日志，以下数据导入时出错，请检查更新后重试：
      </div>
      <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    </div>

    <el-dialog title="提示" :visible.sync="exportCenterVisible" width="30%">
      <div style="padding-top: 20px">
        导出任务已进入队列,可能需要花费一些时间,请稍后在导出中心查看!
      </div>
      <div
        v-if="total > 50000"
        style="color: #f7a934; font-size: 12px; padding-top: 10px"
      >
        温馨提示：单次导出上限为50000条数据
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportCenterVisible = false">关 闭</el-button>
        <el-button type="primary" @click="goToExportCenterList()"
          >查看进度</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      opt: {
        search: [],
        columns: [
          {
            label: "序号",
            key: "serialNumber",
            width: 120,
          },
          {
            label: "问题数据描述",
            key: "errorReason",
          },
        ],
      },
      showErrorList: true, //是否展示错误列表 true--展示 false--不展示
      leaseCount: 0,
      planSuccessCount: 0,
      planFailCount: 0,
      createdDate: "",
      exportCenterVisible: false,
      total: 0,
    };
  },
  activated() {
    this.leaseCount = this.$route.query.leaseCount;
    this.planSuccessCount = this.$route.query.planSuccessCount;
    this.planFailCount = this.$route.query.planFailCount;
    this.createdDate = this.$route.query.createdDate;
    let planState = this.$route.query.planState;
    if ((planState == 2 && this.planFailCount > 0) || planState != 2) {
      this.showErrorList = true;
    } else {
      this.showErrorList = false;
    }
    this.$refs.myList.onRefresh();
  },
  methods: {
    onGet(opt) {
      let dto = {
        current: opt.skip,
        pageSize: opt.limit,
        planId: this.$route.query.id,
      };
      this.post("tenant-service/lease/v1/batch-plan-result/page", dto).then(
        (res) => {
          let pageNum = opt.skip;
          let pageSize = res.size;
          let num = (pageNum - 1) * pageSize;
          res.data.forEach((item, index) => {
            num++;
            item.serialNumber = num;
          });
          opt.cb(res);
        }
      );
    },
    exportErrorLog() {
    let url = this.EXport + "/tenant-service/lease/v1/batch-plan-result/export";
      let dto = {
        planId: this.$route.query.id,
      };
      axios({
        method: "POST",
        url: url,
        data: dto,
        headers: {
          accessToken:
            localStorage.getItem("token") || sessionStorage.getItem("token"),
        },
      })
        .then((res) => {
          console.log(res);
          if (res.data && res.data.code === 0) {
            // 导出中心弹框标志
            this.exportCenterVisible = true;
          } else {
            this.$message({
              showClose: true,
              message: res.data.msg,
              type: "warning",
            });
          }
        })
        .catch((error) => {
            this.$message({
              showClose: true,
              message: error.message, 
              type: "warning",
            });
        });
    },
    // 前往导出中心
    goToExportCenterList() {
      this.exportCenterVisible = false;
      this.$router.push("/main/exportCenter/list");
    },
  },
};
</script>
<style lang="scss" scoped>
.log_content {
  padding-left: 48px;
  padding-bottom: 20px;
  font-weight: 600;
}

.log_list {
  height: calc(100% - 142px);
}

.log_list_tip {
  padding-left: 48px;
  padding-bottom: 20px;
}
</style>